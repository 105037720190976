var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.adsList.length > 0)?_c('div',[_c('div',[_c('b-row',_vm._l((_vm.paginations),function(ads){return _c('b-col',{key:ads.id,attrs:{"lg":"3","md":"6"}},[_c('b-card',[(ads.type === 'Video')?_c('img',{staticStyle:{"width":"100%","height":"200px","object-fit":"cover","border-radius":"10px"},style:(!ads.thumbnail ? 'object-fit: contain' : ''),attrs:{"src":ads.thumbnail
                                ? _vm.$store.getters['app/domainHost'] +
                                  '/' +
                                  ads.thumbnail
                                : _vm.appName,"alt":"","srcset":""}}):_vm._e(),(ads.type === 'Image')?_c('img',{staticStyle:{"width":"100%","height":"200px","object-fit":"cover","border-radius":"10px"},style:(!ads.url ? 'object-fit: contain' : ''),attrs:{"src":ads.url
                                ? _vm.$store.getters['app/domainHost'] +
                                  '/' +
                                  ads.url
                                : _vm.appName,"alt":"","srcset":""}}):_vm._e(),_c('b-card-body',[_c('div',{staticClass:"p-1"},[_c('h4',[_vm._v(_vm._s(ads.title))]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.formDat(ads.startDate) + "-" + _vm.formDate(ads.endDate))+" ")]),_c('b-badge',{attrs:{"pill":"","variant":"secondary"}},[_vm._v(_vm._s(ads.priority))])],1)])]),_c('b-button',{staticClass:"p-1",attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.editAds(ads)}}},[_vm._v("تعديل")])],1)],1)}),1)],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('ek-pagination',{attrs:{"items":_vm.adsList,"pageLength":8},model:{value:(_vm.paginations),callback:function ($$v) {_vm.paginations=$$v},expression:"paginations"}})],1)],1):_c('div',{staticClass:"text-center mt-2"},[_c('h4',[_vm._v("لا يوجد إعلانات")])])}
var staticRenderFns = []

export { render, staticRenderFns }