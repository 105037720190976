<template>
    <div v-if="adsList.length > 0">
        <div>
            <b-row>
                <!--fix add card adverts-->
                <b-col lg="3" md="6" v-for="ads in paginations" :key="ads.id">
                    <b-card>
                        <!-- <img :style="!item.url ? 'object-fit: contain' : ''" 
              :src="item.url ? $store.getters['app/domainHost'] +
                            '/' +
                            item.url: appName
                            "  /> -->
                        <img
                            v-if="ads.type === 'Video'"
                            :style="!ads.thumbnail ? 'object-fit: contain' : ''"
                            style="
                                width: 100%;
                                height: 200px;
                                object-fit: cover;
                                border-radius: 10px;
                            "
                            :src="
                                ads.thumbnail
                                    ? $store.getters['app/domainHost'] +
                                      '/' +
                                      ads.thumbnail
                                    : appName
                            "
                            alt=""
                            srcset=""
                        />
                        <img
                            v-if="ads.type === 'Image'"
                            :style="!ads.url ? 'object-fit: contain' : ''"
                            style="
                                width: 100%;
                                height: 200px;
                                object-fit: cover;
                                border-radius: 10px;
                            "
                            :src="
                                ads.url
                                    ? $store.getters['app/domainHost'] +
                                      '/' +
                                      ads.url
                                    : appName
                            "
                            alt=""
                            srcset=""
                        />
                        <b-card-body>
                            <div class="p-1">
                                <h4>{{ ads.title }}</h4>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        {{
                                            formDat(ads.startDate) +
                                            "-" +
                                            formDate(ads.endDate)
                                        }}
                                    </div>
                                    <b-badge pill variant="secondary">{{
                                        ads.priority
                                    }}</b-badge>
                                </div>
                            </div>
                        </b-card-body>

                        <b-button
                            @click="editAds(ads)"
                            class="p-1"
                            block
                            variant="primary"
                            >تعديل</b-button
                        >
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <b-col cols="12" class="d-flex justify-content-center">
            <ek-pagination
                :items="adsList"
                v-model="paginations"
                :pageLength="8"
            />
        </b-col>
    </div>
    <div v-else class="text-center mt-2">
        <h4>لا يوجد إعلانات</h4>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { $themeConfig } from "@themeConfig";

export default {
    computed: {
        ...mapState({
            adverts: ({ adverts }) => adverts.adverts,
        }),
        ...mapGetters(["adsList"]),
    },
    data: () => ({
        appName: $themeConfig.app.appName,
        paginations: [],
    }),
    methods: {
        ...mapActions(["getAdsList", "getFileLink"]),
        formDate(startDate) {
            let dete = `${new Date(startDate).toLocaleDateString()}`;
            return dete;
        },
        formDat(endDate) {
            let dete = `${new Date(endDate).toLocaleDateString()}`;
            return dete;
        },
        editAds(dto) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_ADS_DTO", dto);
            if (dto.videoUrl != null) {
                this.$store.dispatch("getFileLink", dto.videoUrl);
            }
        },
    },
    created() {
        this.getAdsList();
    },
};
</script>
<style lang="scss" scoped>
.card-body {
    padding: 0px !important;
}
.card .card-title {
    margin-bottom: 0.53rem;
    padding: 12px;
}
.card .card-text {
    padding: 0px 12px;
}
</style>
